<template>
  <form @submit.prevent="formSubmit">
    <Question v-for="question in questions"
              :key="question.id"
              :question="question"
              :radio-button-color="buttonColor"
              @input="event => updateAnswer(question, event.target.value)"
    />

    <SingupButton :copy="config.copy.button" class="mt" @click="formSubmit"/>
  </form>
</template>

<script>
import { mapState } from "vuex";
import SingupButton from "../SignUpButton.vue";
import Question from "./Question.vue";
import { CAMPAIGN_CONFIG as config } from "../../config";

export default {
  name: "QuestionForm",
  components: {
    Question,
    SingupButton,
  },
  data() {
    return {
      answers: {}
    }
  },
  computed: {
    ...mapState({
      signupMoment: state => state.signupMoment
    }),
    questions() {
      return config.additionalQuestions
    },
    buttonColor() {
      return config.colors.buttonColor
    },
  },
  methods: {
    updateAnswer(question, answer) {
      this.answers[question.id] = answer
    },
    formSubmit() {
      this.$emit('save', this.answers)
    }
  }
}
</script>
