import { createStore } from 'vuex'
import { dataLayer, api, removePhoneNumberCountryPrefix } from '@dpgradio/creative'

export default createStore({
  state() {
    return {
      mode: 'inline',
      subscribed: false,
      isSigningUp: false,
      profile: {},
      shouldConfirmMobile: false,
      signupMoment: null,
    }
  },
  mutations: {
    USER_SIGN_IN_STATUS(state, subscribed) {
      state.subscribed = subscribed
    },
    SIGNED_UP(state) {
      state.subscribed = true
    },
    SET_MODE(state, mode) {
      state.mode = mode
    },
    CHANGE_CONFIRMATION_REQUIREMENT(state, confirmationRequirement) {
      state.shouldConfirmMobile = confirmationRequirement
    },
    PROFILE_INFORMATION_FETCHED(state, profileInformation) {
      state.profile = profileInformation
    },
    SET_SIGN_UP_MOMENT(state, signupMoment) {
      state.signupMoment = signupMoment
    },
    SET_IS_SIGNING_UP(state, status) {
      state.isSigningUp = status
    },
  },
  getters: {
    localStorageRegisteredKey(state) {
      return `signup_moment_${state.signupMoment.id}_registered`
    },
  },
  actions: {
    async checkUserSignedIn({ state, commit, getters }) {
      if (!state.signupMoment || !api.radioToken) {
        return
      }

      const subscribed = localStorage.getItem(getters.localStorageRegisteredKey) || false

      commit('USER_SIGN_IN_STATUS', subscribed)
    },

    async signUp({ state, commit, getters}, item) {
      if (state.isSigningUp) {
        return
      }

      commit('SET_IS_SIGNING_UP', true)

      try {
        await api.request().post(`app/sign_up_moments/${state.signupMoment.id}/sign_up`, { code: 'test' })

        dataLayer.pushEvent('signup_success', item)
        localStorage.setItem(getters.localStorageRegisteredKey, true)
        commit('SIGNED_UP')
      } catch (e) {
        dataLayer.pushEvent('signup_failure', item)
        throw e
      } finally {
        commit('SET_IS_SIGNING_UP', false)
      }
    },
    async fetchSignUpMoment({ commit, dispatch }) {
      this.signupMoment = (await api.request().get('app/sign_up_moments/current')).current_moment

      await dispatch('checkUserSignedIn')

      const isEmptyMoment = Object.keys(this.signupMoment).length === 0

      commit('SET_SIGN_UP_MOMENT', isEmptyMoment ? null : this.signupMoment)

      if (this.signupMoment.hiddenAt) {
        const timeTillEnd = new Date(this.signupMoment.hiddenAt) - new Date() + 1000 // 1 second buffer
        setTimeout(() => dispatch('fetchSignUpMoment'), timeTillEnd)
      }
    },

    setMode({ commit }, mode) {
      commit('SET_MODE', mode)
    },

    async fetchProfileInformation({ commit }) {
      const profileInformation = await api.members.me()
      commit('PROFILE_INFORMATION_FETCHED', profileInformation)
    },

    async initConfirmationRequirement({ commit, dispatch }) {
      const confirmedMobile = localStorage.getItem('confirmedMobile')

      if (!confirmedMobile) {
        await dispatch('fetchProfileInformation')
      }

      commit('CHANGE_CONFIRMATION_REQUIREMENT', !confirmedMobile)
    },

    async confirmMobile({ state, commit }, mobile) {
      if (removePhoneNumberCountryPrefix(state.profile.mobile) !== removePhoneNumberCountryPrefix(mobile)) {
        await api.members.updateProfile({ mobile })
      }

      localStorage.setItem('confirmedMobile', true)
      commit('CHANGE_CONFIRMATION_REQUIREMENT', false)
    },
  },
})
