<template>
  <div class="c-preview">
    <h1 class="c-preview__title">{{ copy.title }}</h1>
    <p>
      {{ copy.body }}
    </p>
  </div>
</template>

<script>
import { CAMPAIGN_CONFIG as config } from '../../config';

export default {
  name: "Preview",
  computed: {
    copy() {
      return config.copy.preview
    },
    titleStyle() {
      return {
        color: config.colors.button
      }
    }
  }
};
</script>

<style lang="scss">
.c-preview {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .o-image {
    padding-bottom: 1rem;
  }
}
</style>
