<template>
  <div>
    <div v-if="mobileEmpty">
      <h2>Mogen we je nummer?</h2>
      <p>
        We hebben nog geen nummer van jou! Als je wordt gekozen om mee te spelen, moeten we je natuurlijk kunnen
        bereiken!
      </p>
    </div>
    <div v-else>
      <h2>Controleer je telefoonnummer</h2>
      <p>
        Als je wordt gekozen om mee te spelen, moeten we je natuurlijk kunnen bereiken! Controleer hieronder of je
        telefoonnummer klopt.
      </p>
    </div>
    <div class="c-input-group">
      <input v-model="mobile" type="tel" placeholder="Telefoonnummer" />
    </div>

    <button class="c-button" @click="confirmMobile()">Bevestig</button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { removePhoneNumberCountryPrefix } from '@dpgradio/creative'

export default {
  name: 'MobileConfirmation',
  data() {
    return {
      mobile: '',
    }
  },
  computed: {
    ...mapState({
      originalMobile: (state) => state.profile.mobile,
    }),
    mobileEmpty() {
      return !this.originalMobile || this.originalMobile.length === 0
    },
  },
  mounted() {
    this.mobile = removePhoneNumberCountryPrefix(this.originalMobile)
  },
  methods: {
    async confirmMobile() {
      await this.$store.dispatch('confirmMobile', this.mobile)
      this.$emit('confirm')
    },
  },
}
</script>
