<template>
  <button class="c-button" :disabled="disabled" @click="$emit('signup')">
    {{ copy }}
  </button>
</template>

<script>

export default {
  name: "SingupButton",
  props: {
    copy: String,
    disabled: Boolean
  },
}
</script>
