<template>
  <div class="o-signup-client"
       :class="config.theme"
       :style="componentStyling">
    <div class="o-content">
      <div class="o-header">
        <img alt="header-image" :src="config.backgroundImage" class="c-header-image">
      </div>
      <div class="o-body">
        <div class="o-body__content">
          <component :is="currentStateComponent" v-if="currentStateComponent"></component>
        </div>
      </div>
    </div>
    <div class="o-footer">
      <div v-if="config.copy.action" class="o-action-link" @click="openActionLink">
        {{ config.copy.action }}
      </div>
      <p v-if="config.copy.footer" class="o-legal-text">
        {{ config.copy.footer }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Subscribed from "./components/steps/Subscribed.vue";
import Running from "./components/steps/Running.vue";
import Preview from "./components/steps/Preview.vue";
import { CAMPAIGN_CONFIG as config } from "./config";
import { openLink, hybrid } from '@dpgradio/creative'

export default {
  name: "App",
  components: {
    Running,
    Subscribed,
  },
  computed: {
    ...mapState({
      user: state => state.user.currentUser,
      signupMoment: state => state.signupMoment,
      subscribed: state => state.subscribed,
      mode: state => state.mode,
    }),
    config() {
      return config
    },
    isInline() {
      return this.mode === 'inline'
    },
    currentStateComponent() {
      if (this.subscribed) {
        return Subscribed;
      } else if (this.signupMoment) {
        return Running;
      }

      return Preview;
    },
    componentStyling() {
      return {
        "--background-color": config.colors.background,
        "--text-color": config.colors.text,
        "--button-color": config.colors.button
      }
    },
  },
  methods: {
    openActionLink() {
      if (hybrid.isNativeApp()) {
        hybrid.openUrl( config.actionLink, { mode: 'overlay' })
      } else {
        openLink(config.actionLink)
      }
    }
  },
};
</script>
