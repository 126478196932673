import { createApp } from 'vue'
import { Notifier as AirbrakeNotifier } from '@airbrake/browser'
import App from './App.vue'
import store from './store'
import './assets/css/index.scss'
import './assets/css/themes.scss' // To make it possible to override styling.
import { observeScreenSize } from './utils/screenSizeObserver'
import { setAppCallbacks } from './utils/appCallbacks'
import { dataLayer, configuration, hybrid, setupAirbrake } from '@dpgradio/creative'

const initialize = async () => {
  const app = createApp(App).use(hybrid).use(store)

  // GET STATION
  const parameters = new URLSearchParams(window.location.search)
  await configuration.retrieveConfigForStation(parameters.get('station'), 'signupMoments')

  // PRIVACY
  // TODO: Uncomment when privacy is fixed.
  // privacy.initialize()

  // DATALAYER
  dataLayer.initialize()

  observeScreenSize()
  setAppCallbacks()
  setScreenMode()
  store.dispatch('fetchSignUpMoment')

  // ERROR REPORTING
  if (import.meta.env.PROD) {
    await setupAirbrake(
      AirbrakeNotifier,
      {
        projectId: 511287,
        projectKey: '307103f3b4060deeb4f72468775fe766',
        environment: 'development',
      },
      app
    )
  }

  app.mount('#signup-client')
}

function setScreenMode() {
  const mode = new URLSearchParams(window.location.search).get('mode') ?? 'inline'
  store.dispatch('setMode', mode)

  document.documentElement.classList.add(`${mode}-mode`)
}

initialize()