import headerImage2024BE from './assets/vincent-ski/header.jpg'

const campaign = new URLSearchParams(window.location.search).get('campaign') || 'vincent-ski'

const configs = {
  'vincent-ski': {
    backgroundImage: headerImage2024BE,
    title: 'Vincent Ski',
    theme: 'qmusic-theme',
    actionLink: 'https://qmusic.be/embed/win-een-skireis-dankzij-het-vincent-live-sneeuwalarm',
    colors: {
        background: '#ED3624',
        text: '#FFF',
        button: '#7cbaf7',
    },
    copy: {
        title: 'Meld je aan voor het Vincent Live Sneeuwalarm en win een skivakantie!',
        action: "Lees hier meer over het Vincent Live Sneeuwalarm",
        footer: 'Door mee te spelen ga je akkoord met de actievoorwaarden van het Vincent Live Sneeuwalarm',
        button: 'Doe mee',
        success: {
            "title": "Je bent aangemeld!",
            "body": "Luister naar Vincent Live en wie weet hoor jij jouw naam wel voorbij komen tijdens het Vincent Live Sneeuwalarm!",
        },
        preview: {
            title: 'Jammer, je bent te laat',
            body: new Date().getDay === "5" ? "Deze actie is jammer genoeg afgelopen!" : "Morgen kan je je vanaf 16u opnieuw aanmelden om kans te maken op een skivakantie! ",

        }
    }
  }
}

export default configs
export const CAMPAIGN_NAME = campaign
export const CAMPAIGN_CONFIG = configs[campaign]
